export interface WbtApiData {
  id: number;
  code: string;
  scormVersion: '2004' | '1.2' | string;
  title: string;
  aiccData: string;
  path: string;
}

export default class Wbt {
  id;
  code;
  scormVersion;
  title;
  aiccData: Record<string, string>;
  path;

  constructor (data: WbtApiData) {
    if (data.aiccData && data.aiccData.length > 0) {
      this.aiccData = JSON.parse(data.aiccData);
    } else {
      this.aiccData = this.freshAiccData();
    }
    this.id = data.id;
    this.code = data.code;
    this.scormVersion = data.scormVersion;
    this.title = data.title;
    this.path = data.path;
  }

  // eslint-disable-next-line class-methods-use-this
  freshAiccData (): Record<string, string> {
    return {
      'cmi.core._children': 'student_id,student_name,lesson_location,lesson_status,lesson_mode,entry,score',
      'cmi.core.student_id': '',
      'cmi.core.student_name': '',
      'cmi.core.credit': 'credit',
      'cmi.core.lesson_location': 'index',
      'cmi.core.lesson_status': 'n',
      'cmi.core.lesson_mode': 'null',
      'cmi.core.entry': 'null',
      'cmi.core.session_time': 'null',
      'cmi.core.score._children': 'raw',
      'cmi.core.score.raw': '0',
      'cmi.student_data._children': 'mastery_score',
      'cmi.student_data.mastery_score': '80',
      'cmi.core_lesson.core_lesson': 'null',
      'cmi.location': '',
    };
  }
}
